import React, { useState } from "react";
import footerImage from '../assets/footer-image-final.jpg'

export default function ImageCards() {

    return (
    <div className="Footer">
        <div className="FooterText1">WE CAN'T WAIT TO CELEBRATE WITH</div>
        <div className="FooterText2"> you</div>
    </div>
  )};