import jandjblack from '../assets/jandj-black.png'


export default () => (
    <div className="header">
        <div className="headerImage">
            <img src={jandjblack} alt="J|J" />
        </div>
        <div className="headerText">
            <p className='headerLine1'>WE'RE</p>
            <p className='headerLine2'>engaged</p>
            <p className='headerLine3'>& <br /> INVITE YOU TO CELEBRATE</p>
        </div>
    </div>
  );