import React, { useState } from "react";
import image1 from '../assets/image-1.jpeg';
import image2 from '../assets/image-2.jpeg';
import image3 from '../assets/image-3.jpeg';
import image4 from '../assets/image-4.jpeg';
import image5 from '../assets/image-5.jpeg';
import image6 from '../assets/image-6.jpeg';
import image7 from '../assets/image-7.jpeg';
import image8 from '../assets/image-8.jpeg';
import white from '../assets/white.png'
import Marquee from "react-fast-marquee";
import "react-medium-image-zoom/dist/styles.css";
import Image from "./Image";



export default function ImageCards() {

    return (
    <Marquee
        loop={0}
        style={{'width':'100vw'}}
        gradient={false}
        speed={50}
    >
        <Image 
            image={image1}
        />
        <Image 
            image={image2}
        />
        <Image 
            image={image3}
        />
        <Image 
            image={image4}
        />
        <Image 
            image={image5}
        />
        <Image 
            image={image6}
        />
        <Image 
            image={image7}
        />
        <Image 
            image={image8}
        />
        
    </Marquee>
  )};