import andImage from '../assets/and-circle.png'
import whenImage from '../assets/when-circle.png'
import whereImage from '../assets/where-circle.png'


export default () => (
    <div className="inviteDetails">
        <div className='inviteTitle'>PLEASE <span className='joinus'>JOIN US</span> AS WE CELEBRATE THE ENGAGEMENT OF</div>
        <div className='couple'>Jessica Jimenez</div>
            <span className='and'>
                and
            </span>
            <div className='couple'>Jorge Reyes</div>
            <div className='time'><br /></div>
        <span className='circles'>
            <img src={whenImage} alt="and" />
        </span>
        <div className='day'>SATURDAY</div>
        <div className='date'>DECEMBER 7TH, 2024</div>
        <div className='time'>7 TO 10 PM</div>
        <div className='break'><br /></div>
        <span className='circles'>
            <img src={whereImage} alt="and" />
        </span>
        <div className='venueName'>SUTTON BAR ROOM</div>
        <div className='addressLine1'>1074 1ST AVENUE</div>
        <div className='addressLine2'>NEW YORK, NY 10022</div>
    </div>
  );