import Card from "./components/Card";
import Header from "./components/Header";
import Form from "./components/Form";
import Footer from "./components/Footer"
import InviteDetails from "./components/InviteDetails";
import ImageCards from "./components/ImageCards";
import jandjlogo from './assets/j&jlogo.png'
import jandjlogoblack from './assets/jandj-black.png'
import './App.css'

export default function App() {
  return (
    <div className="App">
      <Header />
      <div className="AppHeaderImageCards">
        <ImageCards />
      </div>
      <InviteDetails />
      <Form />
      <Footer />
    </div>
  );
}
